<template>
  <div class="search-bar">
    <svg-icon icon="search" :size="global.state.isMobile ? 22 : 30"/>
    <input ref="inputRef" class="search-input" :placeholder="placeholder" @input="changeListener" :value="searchText" />
  </div>
</template>

<script>
import GenericSvgIcon from "@/components/generic/GenericSvgIcon";
import { ref, watch, inject } from "vue";
import debounce from "lodash.debounce";

export default {
  name: "GenericSearchBar",
  emits: [
    "search"
  ],
  components: {
    "svg-icon": GenericSvgIcon
  },
  props: {
    placeholder: {
      type: String,
      default: "Search..."
    },
    clear: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const inputRef = ref(null);
    const isMobile = ref(false);
    const searchText = ref("");
    const global = inject("global");

    const changeListener = e => {
      searchText.value = e.target.value;
      debouncedSearch()
    };

    const debouncedSearch = debounce(() => {
      emit('search', searchText.value)
    }, 300)

    watch(() => props.clear, value => {
      if(value) {
        searchText.value = ""
      }
    })

    return {
      changeListener,
      searchText,
      isMobile,
      inputRef,
      global
    }
  }
}
</script>

<style scoped>
  .search-bar {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding:  22px;
    height: 88px;
    border-radius: 25px;
    background-color: #EBEEFF;
  }
  .search-input {
    width: 100%;
    box-sizing: border-box;
    background: none;
    border: none;
    margin: 0 10px;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */

    letter-spacing: 0.002em;
  }

  .search-input:focus {
    outline: none;
  }

  :deep(path) {
    fill: #627DFF;
  }

  @media screen and (max-width: 992px) {
    .search-bar {
      height: calc(var(--oh) * 3.75);
    }
    .search-input {
      font-size: calc(var(--oh) * 1.75);
    }
  }
</style>