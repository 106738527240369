<template>
  <generic-section  bg-color="#FBFCFF" height="auto">
    <h1 class="section-header">{{ $t("FAQ_LONG") }}</h1>
    <span class="section-content">{{ $t("FAQ_CONTENT") }}</span>
    <generic-search-bar class="faq-search-bar" @search="onSearch" :clear="searchBarClear" />
    <div class="categories-container" v-if="categories.length > 0">
      <div ref="backRef" class="categories-navigate-back">
        <div style="padding: 10px 0 10px 10px;"  @click="() => navigateCategories(false)">
          <span class="categories-navigate-button">&lt;</span>
        </div>
      </div>
      <div ref="categoriesRef" class="categories-content">
        <generic-button
            v-for="(item, index) in categories"
            v-bind:key="index"
            :style="index === 0 ? {marginLeft: 0} : (index === categories.length ? {marginRight: 0} : {})"
            :color="activeCategory === item ? '#627DFF' :'#E1E6FD'"
            :text-color="activeCategory === item ? 'white' : '#2E293D'"
            :label="item"
            dense
            :size="global.state.isMobile ? 1 : 1.25"
            style="padding: 5px 25px;"
            class="categories-button"
            @on-click="() => onCategoryClick(item)" />
      </div>
      <div ref="forwardRef" class="categories-navigate-forward">
        <div style="padding: 10px 10px 10px 0;"  @click="() => navigateCategories(true)">
          <span class="categories-navigate-button">&gt;</span>
        </div>
      </div>
    </div>
  </generic-section>
</template>

<script>
import GenericSection from "@/components/generic/GenericSection";
import GenericSearchBar from "@/components/generic/GenericSearchBar";
import GenericButton from "@/components/generic/GenericButton";
import { ref, onMounted, watch, inject, toRefs } from "vue";
export default {
  name: "FaqHeader",
  emits: [
      "search-faq"
  ],
  components: {
    "generic-section": GenericSection,
    "generic-search-bar": GenericSearchBar,
    "generic-button": GenericButton
  },
  props: {
    categories: {
      type: Array,
      default: () => {}
    },
    onCategoryClick: {
      type: Function,
      default: () => {}
    },
    activeCategory: {
      type: String,
      default: ""
    },
    searchKey: {
      type: String,
      default: ""
    }
  },
  setup(props, { emit }) {
    const { categories } = toRefs(props);
    const searchBarClear = ref(false);
    const categoriesRef = ref(null);
    let listenerAdded = false;
    const backRef = ref(null);
    const forwardRef = ref(null);
    const global = inject("global");
    function onSearch(value) {
      emit('search-faq', value);
    }

    watch(categories, value => {
      if(!listenerAdded && value.length > 0 && categoriesRef.value) {
        categoriesRef.value.addEventListener("scroll",navigateCategories)
        listenerAdded = true
      }
    })

    function calculateScroll() {
        setTimeout(() => {
          if(categoriesRef.value.scrollLeft === 0 || props.categories.length === 0) {
            backRef.value.style.display = "none";
          } else {
            backRef.value.style.display = "flex";
          }
          if(categoriesRef.value.scrollLeft + categoriesRef.value.offsetWidth === categoriesRef.value.scrollWidth  || props.categories.length === 0) {
            forwardRef.value.style.display = "none";
          } else {
            forwardRef.value.style.display = "flex";
          }
        }, 200)


    }
    watch(() => props.categories, () => {
      if(!global.state.isMobile) {
        calculateScroll()

      }
    })
    watch(() => props.searchKey, value => {
      if(!value) {
        searchBarClear.value = true;
        setTimeout(() => searchBarClear.value  = false, 200);
      }
    })
    function navigateCategories(isForward) {
      if(isForward) {
        categoriesRef.value.scrollLeft = Math.min(categoriesRef.value.scrollWidth, categoriesRef.value.scrollLeft + 300);
      } else {
        categoriesRef.value.scrollLeft = Math.max(0, categoriesRef.value.scrollLeft - 300);
      }
    }
    onMounted(() => {
      if(!global.state.isMobile) {
        setTimeout(calculateScroll, 500)
        window.addEventListener("resize", calculateScroll)
      }


    })
    return {
      onSearch,
      categoriesRef,
      backRef,
      forwardRef,
      navigateCategories,
      searchBarClear,
      global
    }
  }
}
</script>

<style scoped>
  .faq-search-bar {
    width: 50%;
    margin: 30px 0;
  }
  .section-header {
    margin: 150px 0 12px 0;
    font-weight: bold;
    font-size: 50px;
    line-height: 68px;
    text-align: center;
    color: #2E293D;

  }
  .section-content {
    font-weight: 500;
    font-size: 22px;
    line-height: 150%;
    /* identical to box height, or 33px */

    text-align: center;
    letter-spacing: 0.2px;

    color: #A7A5B2;
  }
  .categories-container {
    position: relative;
    max-width: 100%;
    margin: 62px 0;
  }

  .categories-content {
    max-width: 100%;
    overflow-x: scroll;
    white-space: nowrap;
    scroll-behavior: smooth;
    pointer-events: all;
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
  }
  .categories-content::-webkit-scrollbar {
    display: none;
  }
  .categories-navigate-back {
    position: absolute;
    left: 0;
    height: 100%;
    width: 80px;
    background: linear-gradient(to left, rgba(255,255,255,0), #FBFCFF);
    background: -webkit-linear-gradient(right, rgba(255,255,255,0), #FBFCFF);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    pointer-events: none;
  }
  .categories-navigate-forward {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 180px;
    background: linear-gradient(to left, #FBFCFF, rgba(255,255,255,0));
    background: -webkit-linear-gradient(right, #FBFCFF, rgba(255,255,255,0));
    display: flex;
    justify-content: flex-end;
    align-items: center;
    pointer-events: none;

  }
  .categories-navigate-button {
    color: #627DFF;
    font-weight: 900;
    font-size: 1.5rem;
    pointer-events: initial;
    cursor: pointer;
  }
  @media screen and (max-width: 992px) {
    .faq-search-bar {
      width: 90%;
    }
    .section-header {
      font-size: calc(var(--oh) * 3);
      line-height: 150%;
      margin: calc(var(--oh) * 10) 20vw calc(var(--oh) * 4) 20vw;
    }
    .section-content {
      font-size: calc(var(--oh) * 1.75);
      margin: 0 20vw;
    }
    .categories-button {
      margin: 0 3px;
    }
    .categories-container {
      margin: calc(var(--oh) * 2) 5vw calc(var(--oh) * 5);
    }
    .categories-navigate-back, .categories-navigate-forward {
      display: none;
    }
  }

</style>