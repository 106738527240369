<template>
  <generic-section height="auto">
    <generic-accordion v-if="content.length > 0" :items="content" />
    <empty-result v-if="content.length === 0" :suggestions="suggestions" :on-click="onCategoryChange" />
  </generic-section>
</template>

<script>
import GenericSection from "@/components/generic/GenericSection";
import GenericAccordion from "@/components/generic/GenericAccordion";
import EmptyResult from "./EmptyResult";
export default {
  name: "FaqContent",
  components: {
    "generic-section": GenericSection,
    "generic-accordion": GenericAccordion,
    "empty-result": EmptyResult
  },
  props: {
    content: {
      type: Array,
      default: () => []
    },
    suggestions: {
      type: Array,
      default: () => []
    },
    onCategoryChange: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style scoped>
  .faq-iframe {
    border: none;
    width: 100%;
    height: 100%;
  }
</style>