<template>
  <div class="accordion-container" ref="containerRef">
    <div v-for="(item,index) in items" v-bind:key="index" class="accordion-box" :class="activeItem === index ? 'accordion-box-active' : ''">
      <div class="accordion-header-box" @click="() => setActiveItem(index)">
        <h4 class="accordion-header">{{item.q}}</h4>
        <div class="accordion-header-button" :class="activeItem === index ? 'accordion-header-button-active' : ''">
          <span class="accordion-arrow">&gt;</span>
        </div>
      </div>
      <span class="accordion-content" :class="activeItem === index ? 'accordion-content-active' : ''">{{item.a}}</span>
    </div>
  </div>
</template>

<script>
import animations from "@/utils/animations";
import { ref, onMounted, watch } from "vue";
export default {
  name: "GenericAccordion",
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const activeItem = ref(-1);
    const elemRefs = ref([]);
    const containerRef = ref(null);
    function setActiveItem(index) {
      if(activeItem.value === index) {
        activeItem.value !== -1 && closeAccordion(index)
        activeItem.value = -1
      } else {
        activeItem.value !== -1 && closeAccordion(activeItem.value)
        activeItem.value = index;
        openAccordion(index)
      }
    }
    function openAccordion(index) {
      animations.accordionOpen(containerRef.value.children[index])
    }
    function closeAccordion(index) {
      animations.accordionClose(containerRef.value.children[index])
    }

    const resizeContainers = () => {
      Array.from(containerRef.value.children).forEach(el => {
        el.style.maxHeight = el.children[0].clientHeight + 'px'
      })
    }

    watch(() => props.items, () => {
      resizeContainers()
    }, { flush: "post", deep: true})

    watch(() => props.items, () => {
      setActiveItem(activeItem.value)
    }, { flush: "pre", deep: true})

    onMounted(() => {
      resizeContainers()

    })
    return {
      activeItem,
      elemRefs,
      setActiveItem,
      containerRef
    }
  }
}
</script>

<style scoped>
.accordion-container {
  width: 100%;
}
  .accordion-box {
    display:flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 10px 0;
    overflow: hidden;
    box-shadow: 0 10px 36.65px rgba(0, 0, 0, 0.05);

  }
  .accordion-header-box {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 28px;
    -webkit-flex: 0;
    flex-shrink: 0;

  }
  .accordion-header-button {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background: #2E293D;
    transform-origin: 50% 50%;
    transform: rotate(90deg);
    color: white;
    font-weight: 900;
    transition: all 0.35s;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .accordion-header-button-active {
    transform: rotate(-90deg);
    background: white;
    color: #2E293D;

  }
  .accordion-header {
    color: #2E293D;
    font-weight: 600;
    font-size: 20px;
    margin: 0;
    padding: 5px;
    text-align: start;
    width: calc(100% - 40px)
  }

  .accordion-box-active .accordion-header {
    color: white;
  }

  .accordion-content {
    top: 100px;
    text-align: start;
    color: white;
    padding: 5px 100px 50px 28px ;
    font-weight: 400;
    font-size: 20px;
    opacity: 0;
    transform-origin: top;
    transition: all 0.35s ease-out;
  }

  .accordion-box-active {
    background-color: #627DFF;
  }

  .accordion-content-active {
    opacity: 1;
  }

  .accordion-arrow {
    transform-origin: 50% 50%;
    transform: scaleY(1.8);
    font-weight: 400;
    font-size: 16px;
  }
  @media screen and (max-width: 992px) {
    .accordion-header-box {
      padding: 15px;
    }

    .accordion-content {
      padding: 0 20px 20px;
      font-size: calc(var(--oh) * 1.8);
    }

    .accordion-header {
      font-size: calc(var(--oh) * 2);
      width: calc(100% - calc(var(--oh) * 15))
    }
    .accordion-header-button {
      min-height: calc(var(--oh) * 3.4);
      height: calc(var(--oh) * 3.4);
      min-width: calc(var(--oh) * 3.4);
      width: calc(var(--oh) * 3.4);
    }
    .accordion-arrow {
      font-size: calc(var(--oh) * 1.5);
    }
  }
</style>