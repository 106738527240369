<template>
    <div class="no-search-result">
      <h1>{{ $t("NO_SEARCH_RESULTS_HEADER")}}</h1>
      <div class="search-suggestions">
        <p>{{ $t("DID_YOU_MEAN")}}</p>
        <a v-for="item in suggestions" v-bind:key="item" @click="() => onClick(item)" class="links">{{ item }}</a>
      </div>
      <img src="../../../assets/no-search-results.png" class="no-results-img" />

    </div>
</template>

<script>
export default {
  name: "EmptyResult",
  props: {
    suggestions: {
      type: Array,
      default: () => []
    },
    onClick: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style scoped>
 .links {
   color: #627DFF;
   text-decoration: underline;
   cursor: pointer;
   margin: 0 20px;
   font-weight: bold;
 }
 .no-results-img {
   width: 50%;
 }
 @media screen and (max-width: 992px) {
   .no-results-img {
     width: 90%;
   }
 }
</style>