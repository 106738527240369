<template>
  <div class="page-wrapper">
    <faq-header :categories=" state.searchKey === '' ? state.faqCategories : state.searchedCategories" :on-category-click="onActiveCategoryChange" @searchFaq="searchFaq" :active-category="state.activeCategory" :search-key="state.searchKey" />
    <faq-content v-if="!state.loading" :content="state.searchKey === '' ? state.faqContent[state.activeCategory] : state.searchedContent[state.activeCategory]" :suggestions="state.suggestions" :onCategoryChange="onSuggestion" />
  </div>
 </template>

<script>
import { reactive } from "vue";
import FaqHeader from "@/pages/routes/FAQ/FaqHeader";
import FaqContent from "@/pages/routes/FAQ/FaqContent";
export default {
  name: "index",
  components: {
    "faq-header": FaqHeader,
    "faq-content": FaqContent
  },
  setup() {
    const state = reactive({
      faqContent: {},
      faqCategories: [],
      activeCategory: "General",
      searchKey: "",
      searchedContent: [],
      searchedCategories: [],
      suggestions: [],
      loading: true
    });
    fetch("https://static.cdwscan.com/faq_en.json").then(res => res.json()).then(response => {
      state.faqContent = response
      state.faqCategories = Object.keys(response)
      state.suggestions = Object.keys(response).slice(0,3)
      state.loading = false
    })
    function searchFaq(value) {
      const searchedContent = {}
      Object.entries(state.faqContent).forEach(category => {
        const arr = [];
        category[1].forEach(question => {
          if(question.q.includes(value) || question.a.includes(value)) {
            arr.push(question)
          }
        })
        if(arr.length > 0) {
          searchedContent[category[0]] = arr
        }
      })
      Object.assign(state, {
        searchKey: value,
        searchedContent,
        searchedCategories: Object.keys(searchedContent),
        activeCategory: value !== "" ? (Object.keys(searchedContent)[0] ||  "") : Object.keys(state.faqContent)[0]
      })
    }
    function onActiveCategoryChange(category) {
      state.activeCategory = category
    }
    function onSuggestion(suggestion) {
      state.searchKey = ""
      state.activeCategory = suggestion
    }
    return {
      state,
      onActiveCategoryChange,
      searchFaq,
      onSuggestion
    }
  }
}
</script>

<style scoped>
.page-wrapper {
  width: 100vw;
  box-sizing: border-box;
}
@media screen and (max-width: 992px) {
  .page-wrapper {
    padding: 10px;
  }
}
.faq-container {
  width: 100vw;
  height: 100vh;
  scroll-snap-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>